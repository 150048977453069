.product-description__wrap {
    padding: 2*$gap 0;

    img {
        max-width: 100%;
        display: block;
        margin: auto !important;
        height: auto !important;
    }

    h2 {
        font-size: 1.56rem;
        line-height: 1.87rem;
        font-weight: 400;
    }

    @media (min-width: $desktop) {
        & > .ui.container {
            width: 900px !important;
        }
    }
}

.product {

    &.product-section{
        table{
            border-collapse: collapse;
            border:none;
            color:$color-contrast-low;
            font-size: 0.88rem;
            max-width:100%;
            width: 100% !important;
            tr{
                &:first-child{
                    font-weight:400;
                }
                td,th{
                    border:none;
                    border-bottom:1px solid $border-color;
                    padding:7px 10px;
                    &:first-child{
                        font-weight:400;
                        border-right:1px solid $border-color;
                        padding-left:0;
                    }
                }
            }

        }
    }

    .swiper-wrapper {
        display: flex;
        align-items: center;
        z-index: 0;
    }
    .products-images-slider {
        overflow: hidden;
        margin-bottom: 12px;

        .swiper-slide {
            display: flex;
            align-items: center;
        }
        img {
            height: 25rem !important;
            object-fit: contain;
        }

        .products-images-slider__next {
            @media (min-width: $tablet) {
                display: none;
            }
        }

        .products-images-slider__prev {
            @media (min-width: $tablet) {
                display: none;
            }
        }

        .products-images-slider__pagination {
            text-align: left;

            @media (min-width: $tablet) {
                display: none;
            }
        }

        @media (min-width: $desktop) {
            &:hover {
                .products-images-slider__next {
                    display: flex;
                }

                .products-images-slider__prev {
                    display: flex;
                }
            }
        }
    }

    .products-images-slider-thumbs {
        overflow: hidden;

        .swiper-slide {
            opacity: 0.4;
            width: 25%;
            height: auto;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .swiper-slide-thumb-active {
            opacity: 1;
        }

        @media (max-width: $tablet - 1) {
            display: none;
        }
    }

    @media (min-width: 1300px) {
        .product__gallery-wrapper {
            align-self: flex-start;
        }
        .product-images-gallery {
            display: flex;
            flex-flow: row-reverse nowrap;
            height: 25rem;
        }
        .products-images-slider {
            margin-bottom: 0;
            margin-left: 12px;

            img {
                height: auto !important;
                max-height: 100%;
                object-fit: contain;
            }
        }
        .products-images-slider-thumbs {
            flex: 0 0 100px;

            .swiper-slide {
                width: 100%;
            }
        }
    }

    .model-overlay {
        display: flex;
        width: 100%;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        font-size: 2em;
        font-weight: 800;
        color: $color-inverted-contrast-high;
        opacity: 0.4;
        justify-content: center;
        align-items: center;

        .model-overlay-text {
            text-shadow: 1px 1px $color-contrast-high;
        }
    }

    .product__details {
        color: $color-contrast-low;

        @media (max-width: $desktop - 1) {
            &.column {
                display: grid !important;
            }
        }

        .details__title {
            font-size: 2.38rem;
            color: $color-contrast-high;
            margin: 0;
            font-weight: 400;

            @media (min-width: $desktop) {
                color: $color-contrast-low;
            }

            @media (max-width: $desktop - 1) {
                order: 2;
            }
        }

        .details__product-code {
            font-size: 1.19rem;
            letter-spacing: 0.49px;
            color: #797979;
            display: inline-block;
            margin-bottom: 14px;

            @media (max-width: $desktop - 1) {
                order: 1;
            }
        }

        @media (min-width: $desktop) {
            .details__product-code.ean {
                margin-left: 1rem;
            }
        }

        .details__product-price {
            color: $color-contrast-medium;
            margin: 26px 0;
            font-weight: 600;

            @media (max-width: $desktop - 1) {
                .details__product-price-label {
                    display: block;
                }

                .details__product-price-value {
                    display: block;
                }
            }

            @media (min-width: $desktop) {
                #product-original-price {
                    margin-left: 1em;
                }
            }

            @media (max-width: $desktop - 1) {
                order: 3;
            }

            #product-price {
                color: $primary-darker;
                font-size: 1.6em;
            }
            
            .details__product-price-details {
                color: red;
                font-weight: 600;
                font-size: 0.8em;
            }
            
            .details__product-price-omni {
                color: #707070;
                font-weight: 400;
                font-size: 0.8em;
            }
        }
        
        .details__promotion-labels-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            
            @media (max-width: $desktop - 1) {
                display: block;
                order: 4;
            }
            
            .details__promotion-labels {
                width: 65%;

                @media (max-width: $desktop - 1) {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }

        .details__tabel-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            @media (max-width: $desktop - 1) {
                display: block;
                order: 7;
            }

            .details__table {
                margin-top: 20px;
                margin-bottom: 30px;
                width: 65%;

                @media (max-width: $desktop - 1) {
                    max-width: 100%;
                    width: 100%;
                }

                .warehouse__value {
                    .warehouse__much {
                        color: $amount-much-color;
                    }

                    .warehouse__average {
                        color: $amount-average-color;
                    }

                    .warehouse__little {
                        color: $amount-little-color;
                    }

                    .warehouse__lack {
                        color: $amount-lack-color;
                    }
                    
                    .warehouse__onrequestonly {
                        color: $amount-onrequest-color;
                        
                        img {
                            width: 16px;
                            vertical-align: text-bottom;
                        }
                    }
                }

                .shipping__value {
                    color: $green;
                }

                .item,
                .data {
                    color: #797979;
                    font-size: 0.88rem;
                    border-bottom: 1px solid rgba(121, 121, 121, 0.2);
                    padding-top: 7px;
                    padding-bottom: 7px;
                }

                .item {
                    padding-right: 150px;

                    @media (max-width: $desktop - 1) {
                        padding-right: 0;
                    }
                }

                .data {
                    font-weight: 700;
                    text-align: right;
                }
            }

            .details__energy-card {
                width: 30%;

                & > img {
                    max-height: 360px;
                    width: auto;
                    margin-top: -55px;
                }

                @media (max-width: $desktop - 1) {
                    width: auto;

                    & > img {
                        margin-top: 0;
                    }
                }
            }
        }

        .details__action {
            display: flex;
            align-items: flex-start;
            margin-bottom: $gap;
            margin-left: -$gap/2;
            margin-right: -$gap/2;

            & > * {
                margin-bottom: $gap;
                margin-left: $gap/2;
                margin-right: $gap/2;
            }

            @media (max-width: $desktop - 1) {
                display: block;
                margin-top: 1em;
            }

            @media (max-width: $desktop - 1) {
                order: 6;
            }

            .action__button {
                text-transform: uppercase;
                color: #fff;
                background-color: $button-bg;
                padding: 0.875em 2.81em;
                letter-spacing: 1.2px;
                text-align: center;
                border-radius: 10px;

                @media (max-width: $desktop - 1) {
                    display: inline-block;
                }

                @media (max-width: $tablet - 1) {
                    display: block;
                    margin-bottom: 0.5em;
                }

                &.fav {
                    background-color: transparent;
                    border: 1px solid $primary;
                    padding: 0.875em 1.25em;

                    i {
                        color: $primary;
                        margin: 0 !important;
                    }
                }

                &.catalog-card__button {
                    background-color: #4d72b8;
                    font-weight: 500;
                }
                
                &.info-card__button {
                    background-color: #4d8bc9;
                    font-weight: 500;
                }
                
                &.instruction__button {
                    background-color: #61b9e8;
                    font-weight: 500;
                }
            }
        }

        .details__short-description {
            overflow: auto;

            @media (max-width: $desktop - 1) {
                order: 8;
            }

            img {
                max-width: 100%;
            }
        }

        .details__attributes-wrapper {
            @media (max-width: $desktop - 1) {
                order: 5;
            }
        }

        .details__attributes {
            display: flex;
            flex-direction: column;
            margin: 15px 0;

            .attributes__title {
                color: #797979;
                font-size: 1.2rem;

                @media(min-width: $tablet) and (max-width: $desktop - 1) {
                    font-size: 0.85rem;
                }
            }

            .attributes__item-wrapper {
                display: flex;
                flex-flow: row wrap;
                margin: 1rem 0;
            }

            .attributes__item {
                display: flex;
                flex-direction: column;
                margin-right: 15px;

                .item__color {
                    width: 60px;
                    height: 1rem;
                    box-shadow: 0px 5px 20px #0000001A;
                    border-radius: 10px;
                    border: 2px solid transparent;
                    cursor: pointer;

                    &:focus {
                        outline: none;
                    }

                    &.active {
                        border: 2px solid $primary;
                    }

                    &:hover {
                        box-shadow: 2px 3px 5px #00000033;
                    }

                    @media(min-width: $tablet) and (max-width: $desktop - 1) {
                        height: 2rem;
                    }
                }

                .item__title {
                    font-size: 0.7rem;
                    letter-spacing: 0.31px;
                    color: #797979;
                }
            }

            @media(min-width: $tablet) {
                .optionvaluegroup__label {
                    width: 20% !important;
                    padding-right: 0;

                    h4 {
                        font-size: 1rem;
                    }
                }

                .optionvaluegroup {
                    width: 80% !important;
                }
            }

            @media(max-width: $tablet) {
                .optionvaluegroup__label {
                    padding-top: 0.5rem;
                    padding-bottom: 0;
                }

                .optionvaluegroup {
                    padding-top: 0.5rem;
                    padding-bottom: 1rem;
                }
            }

            .ui.grid.optionvaluegroup__wrap {
                margin-top: 0;
            }
        }

        .details__cart {
            white-space: nowrap;

            #ledlabs-product-adding-to-cart {
                .required.field {
                    position: relative;

                    label {
                        position: absolute;
                        top: -1.5em;
                        left: 0;
                    }
                }
            }
        }

        .details__cards {
            text-align: center;

            .action__button {
                margin-bottom: 0.5em;
                width: 100%;
                padding: 0.875em 0.81em;
            }
        }
    }

    .specification__title {
        font-size: 2.55rem;
        color: #797979;
        display: block;
        margin: 100px 0 30px;
        margin-left: 120px;
    }

    .product__specification {
        padding-left: 120px;

        .details__table {
            margin-top: 20px;
            margin-bottom: 30px;

            .item,
            .data {
                color: #797979;
                font-size: 0.88rem;
                border-bottom: 1px solid rgba(121, 121, 121, 0.2);
                padding-top: 7px;
                padding-bottom: 7px;
            }

            .item {
                padding-right: 200px;
            }

            .data {
                font-weight: 700;
                text-align: right;
            }
        }

        .details__table-info {
            margin-top: 20px;
            margin-bottom: 30px;

            .item,
            .data {
                color: #797979;
                font-size: 0.88rem;
                border-bottom: 1px solid rgba(121, 121, 121, 0.2);
                padding-top: 7px;
                padding-bottom: 7px;
            }

            .data {
                font-weight: 700;
                text-align: right;
            }
        }
    }

    .product__img {
        padding-left: 120px;
        margin:30px 0 150px;
    }

    .optionvaluegroup {
        .ui.basic.button.optionvalue {
            background: #fff !important;
            color: $color-contrast-low !important;
            border: 1px solid $primary;
            border-radius: 10px;
            font-weight: 400;
            font-size: 0.875em;
            box-shadow: none;
            margin-bottom: 0.1em;
            padding: 0.3em 1em;

            &.active {
                font-weight: 600;
                border: 1px solid $primary;
                color: $color-contrast-high !important;
                background: #d3e5eb9e !important;
            }

            &:hover {
                box-shadow: 2px 3px 5px #00000033;
            }
        }
    }

    .variant-without-options.ui.basic.button {
        background: #fff !important;
        color: $color-contrast-low !important;
        border: 1px solid $primary;
        border-radius: 10px;
        font-weight: 400;
        font-size: 0.875em;
        box-shadow: none;
        margin-bottom: 0.5em;

        &.active {
            font-weight: 600;
            border: 3px solid $primary;
            color: $color-contrast-high !important;
            background: #fff !important;
        }

        &:hover {
            box-shadow: 2px 3px 5px #00000033;
        }

        @media(max-width: $tablet - 1 ) {
            width: 100%;
        }
    }

    #sylius-product-selecting-variant {
        box-shadow: none;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;

        & > form {
            display: flex;
            align-items: flex-end;
        }

        .field {
            margin-bottom: 0;
            margin-right: $gap;
        }

        input[type="number"] {
            width: 80px;
        }

        button[type="submit"] {
            background-color: $addtocart-button-bg;
            border-radius: 10px;
            height: min-content;
            text-transform: uppercase;
            font-weight: 400;
            padding: 0.875em 2.81em;
            margin: 0;
            flex: 1;

            &:hover {
                background-color: $addtocart-button-focus;
                box-shadow: 2px 3px 5px #00000033;
            }
        }

        .cart__amount-unit {
            position: absolute;
            left: 0;
            bottom: -15px;
            font-size: 10px;
            color: $color-contrast-low;

            @media(min-width: $desktop){
                bottom: -20px;
                font-size: 14px;
            }
        }
    }
    
    #virtualProductSubproducts {
        td {
            .content {
                a {
                    color: #343538;

                    &:hover {
                        color: #7f7f7f;
                    }

                    * {
                        font-size: 0.7em;
                    }
                }
            }
        }
    }
}

.product__description-wrapper {
    background-color: #121F2E;

    .description__row {
        display: flex;
        flex-direction: row;
        position: relative;

        .row__content {
            display: flex;
            flex-direction: column;
            padding: 50px;
            justify-content: center;

            .content__title {
                font-size: 2.38rem;
                color: #fff;
                margin-bottom: 30px;
            }

            .content__description {
                font-size: 1.19rem;
                color: #93999F;
            }
        }

        .row__img {
            position: relative;
        }
    }
}

.swiper-container-horizontal {
    .product-list-item__addtocart-wrapper {
      grid-area: cartadd;
      margin-bottom: 1em !important;

      &> .addtocart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @media (max-width: $tablet) {
            display: grid !important;
            justify-content: stretch !important;
        }

        .cart__variations.hidden {
          display: none;
        }

        .cart__amount {
          display: flex;
          align-items: center;
          margin-right: $gap;
          position: relative;
          
          @media (max-width: $tablet) {
              margin-bottom: 1.2em;
          }

          &>.cart__amount-unit {
              position: absolute;
              left: 0;
              display: block;
              bottom: -15px;
              font-size: 0.6rem;
              width: 100%;
              text-align: center;
              color: $color-contrast-low;

              @media(min-width: $desktop){
                  bottom: -20px;
                  font-size: 0.7rem
              }
          }

          .amount__more,
          .amount__less {
            border: 1px solid $primary;
            border-radius: 50%;
            height: 22px;
            width: 22px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            color: $primary;
            font-weight: 700;
          }

          .amount__count {
            color: $primary-dark;
            font-size: 1rem;
            font-weight: 700;
            margin: 0 8px;
            padding: 0.5em 0;
            width: 4em;
            text-align: center;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        .button.ui.primary.cart__add {
          text-transform: uppercase;
          color: #fff;
          /*background-color: $button-add-to-cart;*/
          padding: 14px 45px;
          letter-spacing: 1.2px;
          text-align: center;
          border-radius: 10px;
          font-size: 0.55rem;
          font-weight: 700;
          width: auto;
          padding-bottom: 1.5em;
          padding-top: 1.5em;
          margin-right: 0;

          &:hover {
              /*background-color: $button-add-to-cart-hover;*/
          }
        }
      }

      @media (min-width: $tablet) {
          &> .addtocart {
              justify-content: center;

              .cart__add {
                  width: auto;
              }
          }
      }

      .package-info__quantity {
          position: absolute;
          left: 0;
          bottom: -1em;

          small {
              font-size: 0.7rem;
              color: $color-contrast-medium;

              &>.cart__amount-unit {
                  position: relative;
                  left: 1px;
              }
          }


          @media (min-width: $tablet) {
              bottom: -1.5em;
          }
      }

      .ui.mini.warning.message.max-inventory__warning {
          font-size: 0.75rem;
          margin-top: 2em;
          padding: 0.5em 1em;
          white-space: normal;
          display: none;
      }

      .ui.mini.warning.message.max-unprospective__warning {
          font-size: 0.75rem;
          margin-top: 2em;
          padding: 0.5em 1em;
          white-space: normal;
          display: none;
      }
    }
}
