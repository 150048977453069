footer#footer {
  background-color: $primary-darker;
  color: $color-inverted-contrast-high;
  margin: 0;
  font-size: 0.75rem;
  padding: 3*$gap 0;

  h4 {
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }
  a[href] {
    color: $color-inverted-contrast-high;
    transition: color .2s;

    &:hover {
      color: darken($color-inverted-contrast-high, 15%);
    }
  }

  .taxons-menu__content {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: -1.5em;

    & > a.item {
      padding-bottom: 1.5em;
      width: 50%;

      @media (min-width: $tablet) {
        width: 25%;
      }
      @media (min-width: $desktop) {
        width: 50%;
      }
    }
  }

  .store-menu__content {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: -1.5em;

    & > .item {
      display: block;
      padding-bottom: 1.5em;
      width: 50%;

      @media (min-width: $tablet) {
        width: 25%;
      }
      @media (min-width: $desktop) {
        width: 100%;
      }
    }
  }
  .store-menu__title {
    display: none;

    @media (min-width: $desktop) {
      display: block;
    }
  }

  .footer-second {
    border-top: 2px solid transparentize($color-inverted-contrast-high, 0.8);
    margin-top: 2*$gap;
    padding-top: 2*$gap;
    width: 100%;

    .column {
      @media (max-width: $tablet - 1) {
        padding-left:0 !important;
        padding-right: 0 !important;
      }
    }
    
    .footer__phone {
        p {
            margin-bottom: 0.5em;
        }
    }
    
    .four.wide.column.footer__contactus {
        padding: 0;
    }
    
    .footer__copyrights {
        text-align: left;
        font-size: 0.75em;
        color: $color-inverted-contrast-medium;
        margin-top: 1rem;
        word-spacing: 1px;
        text-transform: uppercase;
        
        a {
            color: $color-inverted-contrast-medium;
            
            strong {
                color: $color-inverted-contrast-high;
            }
        }
        
        strong {
            color: $color-inverted-contrast-high;
        }
    }
    
    @media (max-width: $desktop - 1) {
        .footer__plus {
            width: 50% !important;
        }
        .footer__address {
            display: none !important;
        }
        .footer__phone {
            display: none !important;
        }
        .footer__contactus {
            width: 50% !important;
        }
    }
    
    @media (max-width: $tablet - 1) {
        .footer__plus {
            width: 100% !important;
        }
        .footer__address {
            display: none !important;
        }
        .footer__phone {
            display: none !important;
        }
        .footer__contactus {
            width: 100% !important;
        }
    }
  }
}

.footer__content {
  & > *:not(:last-child) {
    border-bottom: 2px solid transparentize($color-inverted-contrast-high, 0.8);
    margin-bottom: 2*$gap;
    padding-bottom: 2*$gap;
  }
  
  .column.column-awards {
      margin-left: -10rem;
      margin-right: -10rem;
      
      @media (max-width: $desktop) {
          margin-left: auto;
          margin-right: auto;
      }
  }
  
  .column.column-socials {
      text-align: right;
      margin-left: -2rem;
      margin-right: 2rem;
      
      .ui.list {
          width: auto;
          text-align: left;
          float: right;
          margin-top: 0;
      }
      
      @media (max-width: $desktop) {
          display: flex;
          justify-content: flex-start;
          gap: 1rem;
          margin-left: auto;
          margin-right: auto;
      }
  }
  
  .footer__content_partners {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      
      a {
          margin: 0.5em 0;
          
          img {
              width: 80%;
          }
      }
  }
  
  .footer__content_diamonds {
      margin-top: 0;
      
      img {
          height: 80px;
          
          @media (min-width: $desktop) {
            margin: 0 auto;
          }
          
          @media (min-width: $desktop) {
            &.gazele__logo {
                height: 110px;
            }
          }
      }
      
      @media (max-width: $desktop) {
          justify-content: center;
          display: flex;
/*          justify-content: space-between;
          margin-right: 0;*/
      }
  }
  
  .footer__content_certificates {
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      justify-content: center;
      gap: 1.2rem;

      img {
          height: 100px;
      }
  }

  @media (min-width: $desktop) {
    display: flex;
    flex-flow: row wrap;

    & > * {
      min-width: 0;
      flex: 1 1 0;

      &:not(:last-child) {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}