.layout-container,
.ui.container {
  width: 100% !important;
  max-width: 100%;
  padding-left: 2*$gap!important;
  padding-right: 2*$gap!important;
  margin-left: auto!important;
  margin-right: auto!important;

  @media (min-width: $tablet) and (max-width: $desktop - 1) {
    width: $container-tablet !important;
  }
  @media (min-width: $desktop) {
    padding-left: 3*$gap!important;
    padding-right: 3*$gap!important;
    width: $container-desktop !important;
  }
}

html, body {
  height: 100%;
  margin: 0;
}

body.pushable {
  .pusher {
    background-color: #fff;
  }
}

.page__content {
  margin: 30px 0;
  
  &.ui.grid {
      margin-top: 0;
  }

  h1.header {
    font-size: 1.56em;
    line-height: 1.87em;
    padding: 45px 0;
    width: 100%;
  }

  .content-page-box {
    box-shadow: 0px 3px 20px #0000001A;
    padding: 81px 138px;
    
    @media( max-width: $desktop ) {
        padding: 1rem;
    }

    h4 {
      font-size: 1.12em;
      margin-bottom: 1em;
    }

    p {
      margin-bottom: 0.3em;

      &.employee-name {
        font-size: 1.12em;
      }
    }

    a {
      color: $link-color;
      word-break: break-word;
    }
  }

  .page-sidebar {
    .ui.accordion {
      .title {
        color: $link-color;
        font-weight: 600;
        border-top: 1px solid $primary;
        padding: 1em 0;

        .angle.right.icon {
          float: right;
        }
      }

      .active.title {
        .angle.right.icon {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      .ui.list {
        margin-left: 15px;
      }

      a {
        color: $color-contrast-high;
        font-size: 0.875rem;
        font-weight: 600;

        &:hover {
          color: $link-color;
        }
      }
      
      .empty-link {
          color: $color-contrast-high;
          font-size: 0.875rem;
      }
    }
  }
}

/* text content - page break */
div[style="page-break-after:always"] {
    margin: -1em 0 0;
    text-align: right;

    &>span {
        display: inline-block !important;

        &:hover {
            cursor: pointer;
        }
    }
}

.grecaptcha-badge {
    z-index: 2;
    
    @media( min-width: $tablet ) and ( max-width: $desktop ) {
        position: static !important;
    }
}

/* Cookie privacy policy */
#close_info_cookie_2:hover{background:#ccc} 
#close_info_cookie_2{
    display:block; 
    line-height:19px; 
    text-align:center; 
    width:20px; 
    height:20px; 
    border-radius:50%; 
    position:absolute; 
    top:6px; 
    right:15px;
} 
#close_info_cookie_2 i.icon{
    margin:0 auto;
} 
#cookie_info{
    box-shadow: 5px 0px 10px 5px rgba(0,0,0,0.2);
    z-index: 999;
    position: fixed;
    bottom: 0;
    width: 95%; 
    max-width:700px;
    max-height: 15vh;
    left: 50%;
    margin-left:-350px;
    background: #fff;     
    border-top-right-radius: 0;     
    border-bottom-right-radius: 0; 
    border-top-left-radius: 0;     
    border-bottom-left-radius: 0;
    font-size: 0.8em;
    
    a {
        color: $primary;
    }
    
    @media( max-width: $desktop ){
        max-width:500px;
        margin-left:-250px;
    }
    
    @media( max-width: $tablet ){
        max-width:310px;
        margin-left:-155px;
        max-height: 25vh;
    }
}
#close_info_cookie:hover {
    cursor: pointer;
} 
.cookie-info-txt-elm{
    padding:10px 40px !important;
    font-size: 0.9em;
    
    div.text-center {
        text-align: right;
    }
}

#cookie_info_modal {
    position: fixed;
    z-index: 900;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

#cookie_info_full {
    position: fixed;
    top: 5vh;
    left: 50%;
    background: #fff;
    padding: 1.5em;
    width: 100%;
    height: 79vh;
    overflow: auto;
    box-shadow: 5px 0px 10px 5px rgba(0,0,0,0.2);
    z-index: 999;
    width: 95%; 
    max-width:700px;
    margin-left:-350px;
    background: #fff;     
    border-top-right-radius: 0;     
    border-bottom-right-radius: 0; 
    border-top-left-radius: 0;     
    border-bottom-left-radius: 0;
    font-size: 0.8em;
    display: none;
    
    h1.ui.header {
        font-size: 1.5rem;
    }
    
    a {
        color: $primary;
        word-break: break-word;
    }
    
    @media( max-width: $desktop ){
        max-width:500px;
        margin-left:-250px;
    }
    
    @media( max-width: $tablet ){
        max-width:80%;
        margin-left:-40%;
        top: 1vh;
        height: 69vh;
    }
}

#distributor-register__form {
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    
    .login-page-slider__container{
        width:100%;
    }
    .register-distributor-btn{
        margin-top:auto;
    }
}

.login-page-slider__container {
    overflow: hidden;
    
    .homepage-slider {
        max-height: 205px;
    }
    
    img.slide-image {
        max-width: 100%;
        width: 702px;
    }
}

.configurator-groups-orders-summary {
    tbody.configurator-groups-orders-summary-header.configurator-list {
        td {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 50%;
                border-left: 1px solid $color-contrast-low;
                border-top: 1px solid $color-contrast-low;
                /*border-radius: 2px 0 0 0;*/
            }
            
            .ui.header {
                a {
                    .sylius-product-name {
                        font-size: 1.3rem !important;
                    }
                }
            }
        }
    }

    tbody.configurator-groups-orders-summary-content {
        td {
            font-size: 0.8em;

            &:first-child {
                padding-left: 1em;
                border-left: 1px solid $color-contrast-low !important;
            }
        }
        
        tr:last-child {
            td {
                &:first-child {
                    position: relative;
                            
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 0%;
                        border-left: 1px solid $color-contrast-low;
                        /*border-top: 1px solid $color-contrast-low;*/
                        /*border-radius: 2px 0 0 0;*/
                    }
                }
            }
        }
    }
    
    .ui.header {
        a {
            color: $base-fonts;
        }
    }
}

.hidden-field {
    display: none !important;
}

.ui.basic.label.presentation-pricing-term-range__label {
    color: #ccc;
    font-size: 0.8em;
    line-height: unset;
    font-weight: 400;
}

.ui.basic.label.presentation-mode-tax-value__label {
    color: #ccc;
    font-size: 0.8em;
    line-height: unset;
    font-weight: 400;
}

.app_shop_user_logo_userLogo_file__wrap {
    border: 1px solid rgba(34,36,38,.15);
    padding: 0.5em;
    border-radius: .28571429rem;
}

.gus-disabled {
    pointer-events: none !important;
    opacity: .45 !important;
}

.address-nip-input__wrap {
    display: flex;
    
    input[type="text"].address-nip-country-code {
        background: #4873A3;
        color: #fff;
        display: flex;
        align-items: center;
        padding-left: 0.5em;
        padding-right: 0.5em;
        width: 39px;
        border: none;
        border-radius: 0;
    }
    
    input[type="text"] {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-left: none;
    }
}

.app_shop_notesFiles_file__wrap {
    border: 1px solid rgba(34,36,38,.15);
    padding: 0.5em;
    border-radius: .28571429rem;
}

#rolls-size-updating__details {
    font-size: 0.9em;
    
    p {
        margin-bottom: 0;
    }
}