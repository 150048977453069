$header-row-height: 45px;

#main-header {
  width: 100%;
  background-color: rgba(255,255,255,0.85);
  backdrop-filter: blur(20px);
  position: relative;
  z-index: 11;
  padding-top: $gap;
  padding-bottom: $gap;
}

#main-header .main-header__inner-container { // Normal -----------------
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: $header-row-height $header-row-height;
  grid-template-areas: "logo nav-bttn" "search search";
  grid-gap: $gap;

  .logo-container {
    grid-area: logo;
    display: flex;
    align-items: center;
    margin-right: $gap;

    img {
      height: 24px;
    }
  }

  .nav-container {
    grid-area: nav;
  }

  .nav-button {
    grid-area: nav-bttn;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    padding: 0.5em 1em;
    margin-right: -1em;
    color: $base-navigation;
  }

  .search-container {
    grid-area: search;
    
    .results {
        .autocomplete-code {
            color: $color-contrast-low;
            margin-left: 1em;
        }
    }
  }

  .cart-container {
    grid-area: cart;
    position: absolute;
    top: calc(#{2*$header-row-height} + #{2*$gap});
    right: 0;
  }
  
  .social-container {
      display: none;
  }

  @media (min-width: $tablet) {
    grid-template-columns: auto 1fr auto;
    grid-template-rows: $header-row-height;
    grid-template-areas: "logo search nav-bttn";

    .cart-container {
      top: calc(#{$header-row-height} + #{$gap});
    }

    .logo-container {
      img {
        height: 32px;
      }
    }
  }

  @media (min-width: $desktop) {
    grid-template-columns: auto 1fr auto;
    grid-template-rows: $header-row-height $header-row-height;
    grid-template-areas: "logo search social" "nav nav cart";

    .cart-container {
      position: relative;
      top: auto;
    }
    .nav-button {
      display: none;
    }
    
    .social-container {
        grid-area: social;
        display: block;
        position: relative;
        white-space: nowrap;
        
        .ui.list {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
  }
  @media (min-width: $wide-desktop) {
    .logo-container {
      img {
        height: 42px;
      }
    }
  }
}

#main-header.stickied .main-header__inner-container { // Stickied -----------------
  grid-template-rows: $header-row-height;
  grid-template-areas: "logo nav-bttn";

  .search-container {
    display: none;
  }
  
  .social-container {
      display: none;
  }

  .cart-container {
    top: calc(#{$header-row-height} + #{$gap});
  }

  @media (min-width: $tablet) {
    grid-template-areas: "logo search nav-bttn";

    .search-container {
      display: block;
    }
  }

  @media (min-width: $desktop) {
    grid-template-columns: auto 1fr auto auto auto;
    grid-template-rows: $header-row-height;
    grid-template-areas: "logo search nav shopbar cart";

    .cart-container {
      top: auto;
    }

    .main-menu > div > a.item {
      margin-right: 2em;
    }
    .main-menu > div:last-child > a.item {
      margin-right: 0;
    }

    .megamenu {
      max-height: calc(100vh - #{$header-row-height} - #{2*$gap});
      overflow-y: auto;
      overscroll-behavior: contain;

      .see-all {
        position: relative;
        width: 100%;
        text-align: right;
      }
    }
    
    .sticky-hidden {
        display: none;
    }
  }
}

/* Main navigation **********************************/
.nav-container {
  display: none;
  position: absolute;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  top: calc(#{$header-row-height} + #{$gap});
  background-color: $primary-darker;
  box-shadow: 0 33px 30px -30px rgba(0, 0, 0, 0.88);
  z-index: 3;
  padding: 0.5*$gap 0 2*$gap;
  overscroll-behavior: contain;

  & > .nav-container__inner {
    @extend .layout-container;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "shopbar" "menu";
    grid-gap: 1*$gap;

    & > .main-menu {
      grid-area: menu;
    }
    & > .shopbar-menu {
      grid-area: shopbar;
    }
  }

  &.open {
    display: block;
  }

  @media (max-width: $desktop - 1) {
    max-height: calc(100vh - #{$header-row-height} - #{2*$gap});
    overflow-y: auto;
  }

  @media (min-width: $tablet) {
    & > .nav-container__inner {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: "menu shopbar";
      grid-gap: 2*$gap;
    }
  }

  @media (min-width: $desktop) {
    background-color: transparent;
    position: static;
    top: 0;
    left: 0;
    width: auto;
    transform: none;
    display: block !important;
    padding: 0;

    & > .nav-container__inner {
      padding: 0 !important;
      margin: 0 !important;
      width: auto !important;
      display: flex !important;
      justify-content: space-between;
    }
  }
}

#main-header .main-menu {
  .megabutton {
    display: flex !important;
    align-items: center;

    .icons {
      position: relative;
      top: 0.1em;
      margin-left: 0.3em;
    }
  }

  @media (max-width: $desktop - 1) {
    color: $color-inverted-contrast-high;

    & > div > a.item { // First level
      display: flex;
      font-size: 1.43em;
      color: $color-inverted-contrast-high;
      border-bottom: 2px solid transparentize($primary, 0.2);
      padding: 0.7em 0;
    }
    & > div:last-child > a.item {
      border-bottom: none;
    }
    .megamenu {
      display: none;

      &.open {
        display: block;
      }
    }
    .megabutton {
      .icons {
        margin-left: auto;
        transition: transform .2s;
      }

      &.open {
        .icons {
          transform: rotate(180deg);
        }
      }
    }

    .megamenu__tabs {
      margin-left: 4rem;

      & > a.item { // Second level
        display: block;
        font-size: 1em;
        font-weight: 700;
        padding: 1em 0;
        color: $color-inverted-contrast-high;
        border-bottom: 2px solid transparentize($primary, 0.2);

        &:last-child {
          border-bottom: none;
        }
      }
    }
    .megamenu__tabs-content {
      display: none;
    }
    
    .megamenu__content {
      display: none;
    }
  }

  @media (min-width: $tablet) and (max-width: $desktop - 1) {

  }

  @media (min-width: $desktop) {
    flex:1;
    display: flex;
    align-items: center;

    /**SEMANTIC UI MEGA MENU**/
    .mega-dropdown:hover{
      & > .megamenu {
        display: flex;
      }
      & > .item {
        border-bottom: 4px solid $primary;
        background: none;
        border-radius: 0;
        cursor: default;
      }
    }

    & > div > a.item { // First level
      display: flex;
      white-space: nowrap;
      border-bottom: 4px solid transparent;
      font-size: 1em;
      color: $color-contrast-high;
      margin-right: 3em;
      margin-bottom: -4px;
      padding-left: 0;
      padding-right: 3px;

      &.active,
      &:hover {
        border-bottom: 4px solid $primary;
        background: none;
        border-radius: 0;
      }
    }

    & > div:last-child > a.item {
      margin-right: 0;
    }

    .megamenu {
      margin: 0 2*$gap;
      padding: 0 $gap $gap $gap;
      background-color: #fff;
      border-radius: 2px;
      width: 70%;
      position: absolute;
      left: 0;
      right: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      display: none;
      margin-top: 4px;
      z-index: 99;

      .ui.tab.active,
      .ui.tab.open {
        display: flex;
      }
    }

    .megamenu__tabs {
      width: 200px;
      flex: 0;

      & > a.item { // Second level
        font-size: 0.875em;
        color: $color-contrast-high;
        display: block;
        border-bottom: 1px solid $primary;
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        margin-right: auto;
        position: relative;
        font-weight: 600;

        &.active,
        &:hover {
          color: $primary;

          &::after {
            content: "\f105";
            font-family: Icons;
            font-size: 1.4em;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 0.9em;
          }
        }
        &:last-child {
          border-bottom: none;
          margin-bottom: -0.5em;
        }
      }
    }
    
    .megamenu__content {
        flex: 1;
        padding-left: $gap;
        padding-bottom: 3*$gap;
    }

    .megamenu__tabs-content {
      flex: 1;
      padding-left: $gap;
      padding-bottom: 3*$gap;

      h4 {  // Third level
        font-size: 0.875em;
        margin-bottom: 0.7em;

        a {
          color: $color-contrast-high;

          &:hover {
            color: $primary;

            .taxon-image-wrapper::after { // Blue image mask
              opacity: 0.3;
            }
          }
        }
      }

      .see-all {
        font-size: 0.875em;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0.7*$gap $gap;
        color: $color-contrast-high;
        font-weight: 700;
        background-color: #fff;
        border-radius: 2px 0 0 0;

        &:hover {
          color: $primary;
        }
      }

      .ui.list {
        margin-top: 0.7em;
      }

      a.item { // Fourth level
        color: $color-contrast-high;
        border-bottom: none;
        font-size: 0.75em;

        &.active,
        &:hover {
          color: $primary;
        }
      }

      .more-link {
        text-align: right;
      }

      .taxon-title{
        display: block;
        margin-bottom: 0.7em;
      }
      .taxon-image-wrapper {
        display: block;
        position: relative;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $primary;
          opacity: 0;
        }
      }
      .taxon-image {
        display: block;
        width: 100%;
        max-width: 100%;
      }

      .ui.grid + .grid {
        margin-top: -1rem;
      }
    }
    
    .menu-item__widget {
        a {
            color: $color-contrast-high;
            font-size: 0.875em;
            padding-bottom: 0.5em;
            padding-top: 0.5em;
            font-weight: 600;

            &.active,
            &:hover {
                color: $primary;
            }
        }
        
        .menu-item-widget__product {
            img {
                width: 50%;
            }
        }
    }
    
    .menu-multilevel {
        a {
            color: $color-contrast-high;

            &.active,
            &:hover {
                color: $primary;
            }
        }
    }
    /**SEMANTIC UI MEGA MENU END**/
  }
}

/* Shopbar menu **********************************/
.shopbar-menu {
  .corner.check.icon {
    top: 1em;
    font-size: 0.7em;
  }
  
  .corner.close.icon {
    top: 1em;
    font-size: 0.7em;
  }

  #ledlabs-channels-selector {
    display: flex;
    align-items: center;

    & > .sylius-active-locale {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: $desktop - 1) {
    display: flex;
    flex-direction: column;

    & > * {
      padding: $gap;
      display: block;
      margin: $gap/3 0;
    }
    * {
      color: $color-inverted-contrast-high;
    }
    .phone-button,
    .user-button {
      border: 2px solid $primary;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;

      .icons {
        margin-right:$gap;
        color: $base-navigation;
      }
    }
    .user-button {
      flex-wrap: wrap;

      .menu {
        position: relative !important;
        width: 100%;
      }
    }
    #sylius-locale-selector {
      font-weight: 700;
      align-self: flex-end;
    }

    #ledlabs-channels-selector {
      font-weight: 700;
      align-self: flex-end;
    }
  }

  @media (min-width: $desktop) {
    display: flex;
    align-items: center;

    & > * {
      padding: $gap/2;
    }
    #sylius-locale-selector {
      padding: $gap/2 $gap;
      display: flex;
      align-items: center;
    }
    .phone {
      color: #212121;
    }
    .button-text {
      display: none;
    }
    .user-button .menu {
      left:50% !important;
      transform: translate(-50%) !important;
    }
    
    .user-button {
        .icons {
            color: $base-navigation;
        }
    }
  }
  
  @media (min-width: $tablet) {
      .phone-button {
          display: none;
      }
  }
  
  @media (min-width: $desktop) {
      .user-button {
          display: flex;
          
          .user-button-label {
              margin-right: 0.5em;
          }
      }
  }
  
  @media (max-width: $desktop) {
      .text.user-button-label {
          display: none;
      }
  }
}


/* Cart button ***********************************/
#sylius-cart-button {
  display: flex;
  align-items: center;
  background-color: #A0DFF3;
  border-radius: 0;
  margin-right: 0 !important;
  padding: 0.4em 2*$gap 0.4em 2*$gap;

  .icon-container {
    margin-right: 0.5em;
    font-size: 1rem;
  }
  .icon.large {
    position: relative;
    top: -1px;
    font-size: 1em;
  }

  .sylius-cart-counter {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: 1em;
    padding: 0.15em 0.35em;
    color: #fff;
    text-shadow: none;
    background-color: $primary;
    border-radius: 1em;
    margin-left: -0.4em;
  }

  @media (min-width: $tablet) {
    padding: 0.4em 0.8em 0.4em 0.8em;
    margin-right: 2*$gap !important;
  }

  @media (min-width: $desktop) {
    padding: 0.78em 1.5em 0.78em 1.5em;
    margin-right: 0 !important;

    .icon.large {
      font-size: 1.5rem;
      top: 0;
    }
    .sylius-cart-counter {
      margin-left: -0.9em;
      top: -0.3em;
    }
  }
}


/* Search *************************************/
#products-search {
  max-width: 700px;
  font-size: 0.76rem;

  .search-group {
    background: #fff;
    border: 1px solid $primary;
    padding-bottom: 0.3em;
    padding-top: 0.3em;

    & > input[type="text"] {
      border-color: transparent;
    }

    .prompt {
      border-radius: 0;
      border: none;
    }

    .ui.selection.dropdown {
      border: none;
      border-left: 1px solid $border-color;
      border-radius: 0;
      min-width: 5em;
    }

    .button.icon {
      box-shadow: none;
      border-radius: none;
      border-color: transparent;
      background: none;
    }
  }

  .results {
    width: 100%;

    &.visible {
      width: 100%;
    }
  }
}
