@import '../../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import 'mixins';
@import 'variables';
@import 'base.scss';
@import 'layout.scss';

// Pages
@import 'pages/homepage';
@import 'pages/productslist.scss';

// Components
@import 'components/product.scss';
@import 'components/header.scss';
@import 'components/footer.scss';
@import 'components/misc.scss';
