// Layout -----------------------------
.product-list {
  .product-list__filters:not(.open) {
    display: none;
  }
  .product-list__sorting:not(.open) {
    display: none;
  }
  .product-list__mobile-topbar {
    display: block;
  }
  .ui.breadcrumb {
    margin-bottom: $gap !important;
  }
  .product-list__description {
    margin-bottom: 2*$gap;
  }

  //@media (max-width: $tablet - 1) {
  //  & > .ui.container {
  //    padding-left: $gap!important;
  //    padding-right: $gap!important;
  //  }
  //}
  @media (min-width: $desktop) {
    & > .ui.container > form {
      display: grid;
      grid-template-columns: 250px 1fr;
      grid-template-rows: auto auto 1fr auto;
      grid-template-areas: ". sorting" "filters desc-top" "filters list" "desc desc";
      grid-gap: 2*$gap;
    }

    .product-list__filters {
      grid-area: filters;
      display: block !important;
    }
    .product-list__sorting {
      grid-area: sorting;
      display: flex !important;
    }
    .product-list__content {
      grid-area: list;
    }
    .product-list__description {
      grid-area: desc;

        &.top {
            grid-area: desc-top;
        }
    }
    .product-list__mobile-topbar {
      display: none;
    }
    .ui.breadcrumb {
      margin-bottom: 0.2rem !important;
    }
  }
}


// List -------------------------------
.product-list__content {
  .swiper-wrapper {
    display: flex;
    align-items: center;
    z-index: 0;
  }
  .products-images-slider {
    img {
      width: auto !important;
      max-height: 20rem;
      margin: auto;
    }
  }
  .products-images-slider-thumbs {
    .swiper-slide {
      width: 25%;
    }
  }

  .model-overlay {
      display: flex;
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      font-size: 2em;
      font-weight: 800;
      color: $color-inverted-contrast-high;
      opacity: 0.4;
      justify-content: center;
      align-items: center;

      .model-overlay-text {
          text-shadow: 1px 1px $color-contrast-high;
      }
  }

  .product-list__head-title {
      color: $color-contrast-medium;
  }
}

.product-list-item {
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
          "header header"
          "gallery attributes"
          "price additional"
          "cta cta"
          "cartadd cartadd";
  grid-gap: $gap;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  padding: $gap;

  @media (min-width: $tablet) {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: 40% 1fr 1fr;
    grid-template-areas:
            "gallery header header"
            "gallery price price"
            "gallery attributes additional"
            "gallery attributes cta"
            "gallery cartadd cartadd";
  }
  @media (min-width: $desktop) {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: 40% 1fr 1.2fr;
    grid-template-areas:
            "gallery header header"
            "gallery attributes price"
            "gallery attributes additional"
            "gallery attributes cta"
            "gallery attributes cartadd";
    grid-gap: 2*$gap;
    padding: 2*$gap;
  }
  @media (min-width: $wide-desktop) {
    padding: 2*$gap 3*$gap;
  }
}

.product-list-item__header {
  grid-area: header;

  a {
    color: $primary-darker;
  }
}
.product-list-item__title {
  letter-spacing: 0.63px;
  color: $primary-darker;
  font-size: 1.25rem;
  font-weight: 700;
  display: inline;

  @media (min-width: $desktop) {
    font-size: 1.56rem;
    display: block;
    margin-bottom: 0.2em;
  }
}
.product-list-item__sku {
  letter-spacing: 0.5px;
  color: $color-contrast-low;
  font-weight: 300;
  font-size: 1.25rem;
  display: inline-block;
  margin-top: 0;

  @media (min-width: $desktop) {
      display: block;
      margin-top: 0.2em;
  }
}

.product-list-item__attributes {
  grid-area: attributes;

  font-size: 0.7rem;

  .attributes__list {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .list__item {
      box-shadow: none;
      padding: 0;
      margin-bottom: 0.5em;
      display: flex;
      flex-flow: row wrap;

      .item__title {
        color: $color-contrast-medium;
        margin-right: 10px;
      }

      .item__value {
        color: $color-contrast-medium;
        font-weight: 900;
      }

      .systems-toggle {
          display: none;
          color: $color-contrast-medium;
          font-weight: 900;
      }

      .systems-toggler {
          cursor: pointer;
      }
    }
  }

  @media (min-width: $desktop) {
    font-size: 1rem;
  }
}

.product-list-item__price {
  grid-area: price;

  .product-list-item__price_net,
  .product-list-item__price_gross {
    display: flex;
    grid-column-gap: $gap;
    flex-wrap: wrap;
    align-items: baseline;

    .price__prefix {
      width: 100%;
    }
    .price__prefix,
    .price__sufix {
      color: $color-contrast-medium;
      font-weight: 900;
      font-size: 0.7rem;
    }

    .price__value {
      color: $primary-darker;
      font-weight: 900;
      font-size: 1.56rem;

      .sylius-product-price {
          font-size: 1.56rem;
      }
      .sylius-product-original-price {
          font-size: 1rem;
      }
    }

    @media (min-width: $tablet) {
        .price__prefix {
            width: auto;
        }
    }

    @media (min-width: $desktop) {
        justify-content: space-between;

        .price__prefix,
        .price__sufix {
          font-size: 1rem;
        }
    }
  }

  @media (min-width: $tablet) {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: -$gap/2;
      left: 0;
      border-bottom: 1px solid $primary;
    }
  }
  @media (min-width: $desktop) {

    &::after {
      bottom: -$gap;
    }
  }
}

.product-list-item__price-details {
    color: red;
    font-weight: 600;
    font-size: 0.8em;
    text-align: right;
    
    @media (max-width: $tablet - 1) {
        text-align: left;
    }
}

.product-list-item__price-omni {
    color: #707070;
    font-weight: 400;
    font-size: 0.8em;
}

.product-list-item__additional {
  grid-area: additional;

  .additional__warehouse,
  .additional__shipping {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .warehouse__title,
  .shipping__title {
    color: $color-contrast-medium;
    font-size: 0.75rem;
    margin-right: 5px;
  }

  .warehouse__value,
  .shipping__value {
    color: $amount-much-color;
    font-weight: 900;
  }

  .warehouse__value {
    & > * {
      display: flex;
      gap: 2px;
    }

    .warehouse__much {
      color: $amount-much-color;
    }

    .warehouse__average {
      color: $amount-average-color;
    }

    .warehouse__little {
      color: $amount-little-color;
    }

    .warehouse__lack {
      color: $amount-lack-color;
    }
    
    .warehouse__onrequestonly {
        color: $amount-onrequest-color;
        
        img {
            width: 16px;
            vertical-align: text-bottom;
        }
    }
  }

  @media (min-width: $desktop) {
    display: flex;
    justify-content: space-between;
  }
}

.product-list-item__cta {
  grid-area: cta;

  display: flex;
  justify-content: space-between;

  .cta__more,
  .cta__fav {
    text-transform: uppercase;
    color: $primary;
    border: 1px solid $primary;
    padding: 14px 25px;
    text-align: center;
    border-radius: 10px;
    letter-spacing: 0.72px;
    font-size: 0.75rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content:center;
  }

  .cta__more {
    display: none;
    margin-right: $gap;
  }

  .cta__fav {
    width: 100%;
  }

  @media (min-width: $desktop) {
    .cta__more {
      display: flex;
    }
    .cta__fav {
      width: auto;
    }
  }
}

.product-list-item__addtocart-wrapper {
  grid-area: cartadd;

  &> .addtocart {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cart__variations.hidden {
      display: none;
    }

    .cart__amount {
      display: flex;
      align-items: center;
      margin-right: $gap;
      position: relative;

      &>.cart__amount-unit {
          position: absolute;
          left: 0;
          display: block;
          bottom: -15px;
          font-size: 10px;
          width: 100%;
          text-align: center;
          color: $color-contrast-low;
          
          @media(min-width: $desktop){
              bottom: -20px;
              font-size: 14px;
          }
      }

      .amount__more,
      .amount__less {
        border: 1px solid $primary;
        border-radius: 50%;
        height: 22px;
        width: 22px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        color: $primary;
        font-weight: 700;
      }

      .amount__count {
        color: $primary-dark;
        font-size: 1.375rem;
        font-weight: 700;
        margin: 0 8px;
        padding: 0.5em;
        width: 3em;
        text-align: center;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .cart__add {
      text-transform: uppercase;
      color: #fff;
      background-color: $addtocart-button-bg;
      padding: 14px 45px;
      letter-spacing: 1.2px;
      text-align: center;
      border-radius: 10px;
      font-size: 0.75rem;
      font-weight: 700;
      width: 100%;
      padding-bottom: 1.5em;
      padding-top: 1.5em;
      margin-right: 0;

      &:hover {
          background-color: $addtocart-button-focus;
      }
    }
  }
}

.product-gallery-wrapper {
  grid-area: gallery;

  overflow: hidden;
  display: flex;
  align-items: center;

  .product-images-gallery {
    min-width: 0;
    margin: auto;
  }

  .products-images-slider {
    overflow: hidden;
    margin-bottom: 12px;

    .products-images-slider__next,
    .products-images-slider__prev,
    .products-images-slider__pagination{
        display: none;
    }

    @media (min-width: $desktop) {
        &:hover {
            .products-images-slider__next {
                display: flex;
            }

            .products-images-slider__prev {
                display: flex;
            }
            .products-images-slider__pagination{
                display: flex;
            }
        }
    }
  }

  .products-images-slider-thumbs {
    overflow: hidden;

    .swiper-slide {
      opacity: 0.4;
      height: auto;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }

    @media (max-width: $tablet - 1) {
      display: none;
    }
  }

  @media (min-width: 1300px) {
    .product-images-gallery {
      display: flex;
      flex-flow: row-reverse nowrap;
      height: 20rem;
    }
    .products-images-slider {
      margin-bottom: 0;
      margin-left: 12px;

      .swiper-slide {
        display: flex;
        align-items: center;

        a {
          height: 100%;
          display: flex;
          align-items: center;
        }

        img {
          height: auto !important;
          width: 100% !important;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }
    .products-images-slider-thumbs {
      flex: 0 0 80px;

      .swiper-slide {
        width: 100%;
      }
    }
  }
}


// Filters -----------------------------
.product-list__filters {
  padding-bottom: 2*$gap;

  .filters__box {
    padding: 15px 0;
    border-bottom: 1px solid $primary;
  }

  .filters-wrapper {
    & > .item {
      margin-top: $gap;
    }
  }

  .filters__cat {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $primary;

    .cat__header {
      color: $primary;
      font-size: 0.875rem;
      font-weight: 900;
      margin-bottom: 10px;
    }

    .cat__item {
      color: $color-contrast-high;
      font-size: 0.875rem;
      font-weight: 700;
      padding-left: 20px;
      margin: 5px 0;
    }
  }

  .filters__brand {
    .brand__header {
      font-size: 0.875rem;
      font-weight: 700;
      color: $color-contrast-high;
      margin-bottom: 10px;
      display: inline-block;
    }

    .brand__list {
      display: flex;
      flex-wrap: wrap;

      .brand__list-item {
        margin: 8px 17px 8px 0;
        border: 1px solid rgba(0, 185, 228, 0.2);
        position: relative;
      }

      .brand__list-link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .filters__list {
    .list__header {
      font-size: 0.875rem;
      font-weight: 700;
      color: $color-contrast-high;
      margin-bottom: 10px;
      display: inline-block;
    }

    .list__options {
      display: flex;
      flex-direction: column;

      .list__options-item {
        display: flex;
        margin: 10px 0;
      }

      label {
        color: #737680;
        font-size: 0.75rem;;
        margin-left: 15px;
      }

      input {
        width: 20px;
        height: 20px;
      }
    }
  }

  .hiddenoptions {
      display: none;
  }
}


// Sorting -----------------------------
.product-list__sorting {
  padding: 10px 20px;
  background-color: $primary-dark;
  display: flex;
  align-items: center;

  flex-direction: row-reverse;
  justify-content: space-between;

  margin-bottom: 2*$gap;

  .label {
    font-size: 0.75rem;
  }

  .dropdown {
    color: #fff;
    font-weight: 700;
    background-color: $primary-dark;
  }

  .filters__pagination {
    display: none;
  }

  .ui.simple.dropdown:hover>.menu {
      width: 100%;
  }

  @media (min-width: $desktop) {
    flex-direction: row;
    justify-content: stretch;
    margin-bottom: 0;

    .separator {
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid rgba(255, 255, 255, 0.45);
    }
    .label {
      opacity: 0.7;
      color: #FFFFFF;
    }
    .filters__pagination {
      display: block;
      margin-left: auto;

      .pagination__title,
      .pagination__sufix {
        color: #FFFFFF;
        font-size: 0.75rem;
        opacity: 0.7;
      }

      .pagination__active,
      .pagination__end {
        font-weight: 700;
        color: #fff;
        padding: 0 8px 3px 8px;
        margin: 0 6px;
      }

      .pagination__active {
        border-bottom: 1px solid #fff;
      }

      .pagination__prev,
      .pagination__next {
        color: #fff;
        opacity: 0.7;
        font-size: 1rem;
      }
    }
  }
}


// Mobile topbar
.product-list__mobile-topbar {
  background-color: $primary-dark;
  color: $color-inverted-contrast-high;
  display: flex;
  padding: 2.5*$gap 0 0 0;
  margin-bottom: 2*$gap;

  .filters-button,
  .sorting-button {
    appearance: none;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    color: $color-inverted-contrast-high;
    margin-right: $gap;
    outline: none !important;
    border-bottom: 4px solid transparent;
    padding: $gap/2 $gap;

    &.open {
      border-bottom-color: $primary;
    }
  }
}
