// Social box
.social-box {
  position: fixed;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 2;
  margin: 0;

  .item {
    margin-bottom: 30px;

    a {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

// Breadcrumbs
.ui .breadcrumb {
  color: #697D95;
  font-size: 0.75rem;
  margin: 0.2rem 0 !important;
  line-height: 1.2rem;
}

.ui .breadcrumb a {
  color: $primary;

  .active.section {
    font-weight: 400;
  }
}

// Coverlink
.coverlink {
  position: absolute;
  font-size: 0;
  text-indent: -999999px;
  background: transparent;
  z-index: 1;
  width: 100%;
  height: 100%;
  top:0;
  bottom: 0;
}

/* Empty paragraphs */
p:empty {
  display: none;
}

/* Button more */
.button {
  display: inline-block;
  outline: 0;
  min-height: 1em;
  border: none;
  vertical-align: baseline;
  margin: 0 .25em 0 0;
  padding: 1em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  user-select: none;
  background: $button-bg;
  border-radius: 10px;
  color: #fff;
  font-size: 0.75rem;
  transition: color .2s, background-color .2s, box-shadow .2s !important;
  cursor: pointer;

  &:hover {
    background-color: $focus-color;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  }
}
.button--more {
  width: 10.8em;
  text-transform: uppercase;
  color: #fff !important;
}


/* Swiper */
.swiper-button-disabled {
  filter: grayscale(100%);
  opacity: 0.2 !important;
  cursor: default;
}
.swiper-slide {
  & > * {
    transition: opacity .3s !important;
    will-change: opacity;
    box-sizing: border-box;
  }

  &:not(.swiper-slide-visible) > * {
    opacity: 0.5;
  }
}
.swiper-button-prev.swiper-button-black, 
.swiper-button-next.swiper-button-black {
    color: $base-navigation !important;
}
.arrow.left,
.arrow.right {
    color: $base-navigation;
}


/* Sliders */
.products-latest-slider,
.products-related-slider,
.products-gallery-slider,
.products-gallery-in-content-slider,
.articles-gallery-slider,
.articles-gallery-in-content-slider,
.blog-latest-slider-news,
.blog-latest-slider-articles,
.blog-latest-slider {
  $space-between: 20px; // Defined in js on swiper initialisation (need to be in px)
  /*$slide-width: 290px;*/
  user-select: none;

  //width: calc(100% - #{$slide-width - $space-between});
  overflow: hidden;
  padding: $gap 0;

  .swiper-wrapper {
      z-index: 0;
  }
  
  .ui.card {
    height: auto;
    max-width: calc(100vw - #{6*$gap});
    /*width: $slide-width !important;*/
    width: auto;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.products-recommended-slider {
    $space-between: 20px; // Defined in js on swiper initialisation (need to be in px)
    $slide-width: 134px;
    user-select: none;

    //width: calc(100% - #{$slide-width - $space-between});
    overflow: hidden;
    padding: $gap 0;

    .ui.card {
        height: auto;
        max-width: calc(100vw - #{6*$gap});
        /*width: $slide-width !important;*/
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        
        &:first-child {
            margin-top: 0;
        }
        
        .sylius-product-price {
            color: $primary;
            font-size: 0.95em;
            font-weight: 600;
            margin-top: 0.8em;
        }
        
        &:hover {
            .content h3 {
                text-decoration: underline;
            }
        }
    }
}

/* Products latest and related */
.products-latest-container,
.products-gallery-container,
.products-gallery-in-content-container,
.products-related-container {
  .ui.card {
    border-radius: 0;
    box-shadow: 0 3px 20px rgba(0,0,0,0.1);

    &>:first-child {
      border-radius: 0 !important;
    }

    a.image {
      display: flex;
      align-items: center;
      min-height: 270px;
      max-height: 270px;
      overflow: hidden;
      padding: 11px 15px;
      background-color: #fff;
    }

    .content {
      border-top: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p.power {
        color: $green;
        font-size: 0.75em;
        font-weight: 700;
      }

      h3 {
        margin-bottom: 0.8em;
        margin-top: 0;
        font-size: 0.82em;
        font-weight: 400;

        a {
          color: $color-contrast-high;
        }
      }

      small.product-code {
        margin-top: 0.8em;
        font-size: 0.55em;
        color: $color-contrast-low;
      }
      
      .sylius-product-price {
          color: $primary;
          font-size: 0.95em;
          font-weight: 600;
          margin-top: 0em;
      }
      
      .sylius-original-price {
          color: $color-contrast-low;
          font-size: 0.55em;
          font-weight: 400;
          margin-top: 0em;
      }
    }
  }
}

.products-recommended-container {
    position: relative;
    
    .products-recommended-slider__prev {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 999;
        color: rgba(255,255,255,0.87);
        cursor: pointer;
        font-size: 1.2em;
        margin-left: 0.25rem;
    }
    .products-recommended-slider__next {
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 999;
        color: rgba(255,255,255,0.87);
        cursor: pointer;
        font-size: 1.2em;
    }
    
    .ui.card {
        border-radius: 0;
        box-shadow: 0 3px 20px rgba(0,0,0,0.1);

        &>:first-child {
            border-radius: 0 !important;
        }

        a.image {
            display: flex;
            align-items: center;
            min-height: 95px;
            max-height: 95px;
            overflow: hidden;
            padding: 11px 15px;
            background-color: #fff;
        }

        .content {
            border-top: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            p.power {
                color: $green;
                font-size: 0.75em;
                font-weight: 700;
            }

            h3 {
                margin-bottom: 0;
                margin-top: 0;
                font-size: 0.82em;
                font-weight: 400;

                a {
                    color: $color-contrast-high;
                }
            }

            small.product-code {
                margin-top: 0.8em;
                font-size: 0.55em;
                color: $color-contrast-low;
            }
        }
    }
}

.products-gallery-in-content-container {
    max-width: 478px;
    
    @media (min-width: $desktop) {
        max-width: 866px;
    }
}

.articles-gallery-in-content-container {
    max-width: 478px;
    
    @media (min-width: $desktop) {
        max-width: 866px;
    }
    
    .articles-gallery-in-content-slider {
        .extra.content {
            padding: .75em 1em;
        }
    }
}

.article-in-slider__content {
    max-height: 140px;
    overflow: hidden;
    
    h3 {
        margin-bottom: 1.1em !important;
        
        a.article-in-slider-title {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    
    
    .article-in-slider__short-description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 0.85em;
    }
}

.products-latest-container__header,
.products-gallery-container__header,
.products-gallery-in-content-container__header,
.articles-gallery-container__header,
.articles-gallery-in-content-container__header,
.products-related-container__header {
  padding-top: 3*$gap;
  margin-bottom: 2rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}
.products-latest-container__title,
.products-gallery-container__title,
.products-gallery-in-content-container__title,
.products-related-container__title {
  font-size: 2.56rem;
  color: $color-contrast-low;
  font-weight: 400;
}
.products-latest-container__buttons,
.products-gallery-container__buttons,
.products-gallery-in-content-container__buttons,
.articles-gallery-container__buttons,
.articles-gallery-in-content-container__buttons,
.products-related-container__buttons {
  user-select: none;
}
.products-latest-container__content,
.products-gallery-container__content,
.products-gallery-in-content-container__content,
.products-related-container__content {
  margin-bottom: 4*$gap;
}


/* Bootstrap grid classes prosthesis */
.row {
  display: flex;
  margin-left: -$gap;
  margin-right: -$gap;
  flex-wrap: wrap;

  > * {
    padding: $gap;
    width: 100%;
  }
}
.col-sm-6 {
  @media (min-width: $tablet) {
    width: 50%;
  }
}

/* price tax label */
span.label__tax {
    color: rgba(0,0,0, 0.6);
    font-size: 0.75em;
}
.cart-shipping-total__paid {
    margin-bottom: 0;
    color: rgba(0,0,0, 0.6);
    font-size: 0.75em;
}

/* image placeholder */
.product-image__placeholder {
    max-width: 100%;
}