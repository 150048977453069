// Colors
$primary-light: #28c9fc;
$primary: #00a9de;
$primary-dark: #0D396E;
$primary-darker: #121F2E;
$base-fonts: rgba(0, 0, 0, 0.87);
$base-navigation: rgba(45, 45, 45, 0.87);

$color-inverted-contrast-medium: #93999F;
$color-inverted-contrast-high: #F7F7F7;

$color-contrast-low: #797979;
$color-contrast-medium: #707070;
$color-contrast-high: #343538;

$green: #00D811;

$button-bg: $primary;
$addtocart-button-bg: #6fc382;
$addtocart-button-focus: #89d79b;
$focus-color: $primary-light;
$border-color: rgba(0, 0, 0, 0.16);
$link-color: $primary;

$amount-much-color: #00D811;
$amount-average-color: $primary;
$amount-little-color: orange;
$amount-lack-color: red;
$amount-onrequest-color: #ff00ff;

// Typography
$base-font-size: 14px;
$base-line-height: 1.5;

// Spacing
$gap: 1rem;

// Breakpoints
$wide-desktop: 1600px;
$desktop: 1200px;
$tablet: 768px;
$mobile: 320px;

$container-desktop : 1630px;
$container-tablet : 656px;
