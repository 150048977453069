/* Top section *************************/
.homepage-top-container {
  position: relative;
  z-index: 1;

  @media (min-width: $desktop) {
    display: grid !important;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: $gap;
    grid-template-areas: "slider bannerconfigurator" "slider bannerplatform";
  }
  @media (max-width: $tablet - 1) {
    &.ui.container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.lights-banner {
    display: none;
    margin: 0;
    overflow: hidden;
    grid-area: slider;

    .homepage-slider {
        height: 100%;
        position: relative;
        
        .swiper-slide {
            min-height: 508px;
        }

        .slider-content {
            padding: 3em 0em 3em 3em;
            z-index: 1;
            position: absolute;
            width: 100%;

            .slide-header {
                font-size: 4em;
                font-weight: 700;
                line-height: 0.9em;
                margin-bottom: 0.3em;
                text-transform: uppercase;
            }

            .slide-description {
                font-size: 1.2em;
                font-weight: 300;
                margin-bottom: 0.3em;
                text-transform: uppercase;
            }
        }
        
        .slide-link {
            background: none;
            font-size: 0.7em;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-weight: 400;
            margin-top: 3em;
            margin-bottom: 0.5em;
            border: 2px solid #fff;
            border-radius: 50px;
            width: auto;
            padding-top: 1em;
            padding-bottom: 1em;
            text-align: left;
            transition: all .3s ease;
            z-index: 1;
            position: absolute !important;

            &.ui.labeled.icon.button>.icon, 
            &.ui.labeled.icon.buttons>.button>.icon {
                background-color: rgba(0,0,0, 0);
            }

            &:hover {
                border: 2px solid #cdc108 !important;
                background-color: #cdc108 !important;
            }
        }

        .slide-image {
            position: absolute;
            top: 50%;
            right: 0;
            max-width: 100%;
            z-index: 0;
            transform: translateY(-50%);
        }
        
        .homepage-slider__prev {
            position: absolute;
            top: 50%;
            left: auto;
            z-index: 100;
        }
        .homepage-slider__prev {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 999;
            color: rgba(255,255,255,0.87);
            cursor: pointer;
        }
        .homepage-slider__next {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 999;
            color: rgba(255,255,255,0.87);
            cursor: pointer;
        }
        
        @media (max-width: $desktop) {
            display: none;
        }
    }
    
    .homepage-slider-plug {
        @media (max-width: $tablet) {
            display: block;
        }
        
        @media (min-width: $desktop) {
            display: none !important;
        }
    }

    @media (min-width: $tablet) {
        display: block;
    }
}

.homepage-banner-configurator__wrap {
    padding: 0;
    background-color: transparent;
    grid-area: bannerconfigurator;

    @media (min-width: $tablet) and (max-width: $desktop - 1) {
        width: 400px;
        margin: auto;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50px);
        padding: $gap;
    }

    @media (max-width: $desktop) {
        gap: $gap;
    }

    .bitbag-block {
        text-align: center;

        h2 {
            font-size: 1.85rem;
            color: $color-inverted-contrast-high;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        p {
            color: $color-inverted-contrast-high;
            margin-bottom: 1.5rem;
        }

        &.homepage-banner-configurator__content {
            display: block;
            height: 100%;
            overflow: hidden;
            
            .banner-configurator__slider {
                height: 252px;
                position: relative;

                .swiper-slide {
                    min-height: 252px;
                }

                .slide-link.button {
                    font-size: 0.7em;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    font-weight: 400;
                    margin-top: 0;
                    margin-bottom: 0;
                    width: auto;
                    padding-top: 1em;
                    padding-bottom: 1em;
                    text-align: left;
                    transition: all .3s ease;
                    z-index: 1;
                    position: absolute !important;
                    bottom: 5%;
                    left: 1rem;
                    border-radius: 0;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                .slide-image {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    max-width: 100%;
                    z-index: 0;
                    transform: translateY(-50%);
                }

                @media (max-width: $desktop) {
                    display: none;
                }
            }
        }
    }
}

.homepage-banner-platform__wrap {
    display: none;
    background-color: transparent;
    color: $color-inverted-contrast-high;
    overflow: hidden;
    grid-area: bannerplatform;
    padding: 0;
    
    @media (min-width: $desktop) {
        display: block;
    }
    
    .bitbag-block {
        text-align: center;

        h2 {
            font-size: 1.85rem;
            color: $color-inverted-contrast-high;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        p {
            color: $color-inverted-contrast-high;
            margin-bottom: 1.5rem;
        }

        &.homepage-banner-platform__content {
            display: block;
            height: 100%;
            overflow: hidden;
            
            .banner-platform__slider {
                height: 252px;
                position: relative;

                .swiper-slide {
                    min-height: 252px;
                }

                .slide-link.button {
                    font-size: 0.7em;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    font-weight: 400;
                    margin-top: 0;
                    margin-bottom: 0;
                    width: auto;
                    padding-top: 1em;
                    padding-bottom: 1em;
                    text-align: left;
                    transition: all .3s ease;
                    z-index: 1;
                    position: absolute !important;
                    bottom: 5%;
                    left: 1rem;
                    border-radius: 0;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                .slide-image {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    max-width: 100%;
                    z-index: 0;
                    transform: translateY(-50%);
                }

                @media (max-width: $desktop) {
                    display: none;
                }
            }
        }
    }
}

/* Taxons section **************************/
.homepage-taxons {
  background-color: $primary-darker;
  margin-top: -70px;
  padding-top: calc(70px + #{3*$gap});
  padding-bottom: 3*$gap;
  font-size: 1rem;
  color: $color-inverted-contrast-high;

  @media (min-width: $tablet) and (max-width: $desktop - 1) {
    padding-top: 150px;
  }
}

.homepage-taxons-container {
  $border-gap: 10px;
  $border-color: transparentize($color-inverted-contrast-high, 0.7);
  display: block;
  height: 100%;
  margin: 0;

  & > * {
    border-bottom: 1px solid $border-color;
    padding: 2*$gap 0;
  }
  & > *:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .A {
    grid-area: A;
  }
  .B {
    grid-area: B;
  }
  .C {
    grid-area: C;
  }
  .D {
    grid-area: D;
  }
  .E {
    grid-area: E;
  }
  .F {
    grid-area: F;
  }
  .G {
    grid-area: G;
  }

  @media (min-width: $tablet) and (max-width:$desktop - 1) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0;
    grid-template-areas: "A B" "D D" "C E" "F G";

    & > * {
      border-bottom: none;
      padding: 2*$gap !important;
    }
    .A {
      @include box-separator($border-color, (right), $border-gap);
    }
    .C {
      @include box-separator($border-color, (right, bottom), $border-gap);
    }
    .D {
      @include box-separator($border-color, (top, bottom), $border-gap);
    }
    .E {
      @include box-separator($border-color, (bottom), $border-gap);
    }
    .F {
      @include box-separator($border-color, (right), $border-gap);
    }
  }
  @media(min-width: $desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 0;
    grid-template-areas: "A B C" "D D C" "E F G";

    & > * {
      border-bottom: none;
      padding: 2*$gap !important;
    }
    .A {
      @include box-separator($border-color, (right), $border-gap);
    }
    .C {
      @include box-separator($border-color, (left, bottom), $border-gap);
      .homepagebox {
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content 1fr min-content;
        grid-template-areas: "title" "content" "img" "more";
      }
    }
    .D {
      @include box-separator($border-color, (top, bottom), $border-gap);
    }
    .F {
      @include box-separator($border-color, (left, right), $border-gap);
    }
  }
}

.homepagebox {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr min-content;
  grid-gap: $gap;
  grid-template-areas: "title img" "content img" "more img";

  &:hover {
    .homepagebox__imgwrapper img {
      filter: drop-shadow(0px 0px 3.5rem rgba(255, 255, 255, 0.8));
      transform: scale(1.1);
    }
    .homepagebox__more {
      background-color: $focus-color;
    }
  }
}
.homepagebox__title {
  grid-area: title;
  font-size: 1.875rem;
  letter-spacing: 0.75px;
  margin-bottom: 0;
  font-weight: 300;
}
.homepagebox__content {
  grid-area: content;

  p {
    opacity: 0.57;
    letter-spacing: 0.4px;
    line-height: 1.7em;
  }
}
.homepagebox__imgwrapper {
  grid-area: img;
  display: grid;
  align-items: center;
  justify-content: right;

  img {
    max-height: 200px;
    max-width: 100%;
    width: auto;
    transition: filter .3s, transform .3s;
    will-change: filter, transform;

    @media(min-width: $tablet) {
      width: 100%;
      max-height: unset;
    }
  }
}
.homepagebox__more {
  grid-area: more;
}


/* About us section ***************************/
.homepage-aboutus {
  color: $color-contrast-low;
  font-size: 1.18rem;

  h2 {
    font-size: 2.38rem;
    font-weight: 400;
  }
  
  @media (min-width: $desktop) {
    display: grid !important;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: $gap;
  }
  @media (max-width: $tablet - 1) {
    &.ui.container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  @media (min-width: $tablet) {
    @include expanded-left-column(0.5, 0.5, $container-tablet);
    grid-template-areas: "video content";
  }
  @media (min-width: $desktop) {
    @include expanded-left-column(0.666, 0.333, $container-desktop);
    grid-template-areas: "video content";
  }
}

.homepage-aboutus__video {
  grid-area: video;
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.homepage-aboutus__content {
  grid-area: content;
  padding: 3*$gap 2*$gap;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .button--more {
    font-size: 0.9rem;
  }

  @media(min-width: $desktop) {
    padding: 3*$gap;
  }
}


/* Latest products section *********************/
.homepage-latestproducts-container {
  background: transparent url('../../media/latestbg.png') 0% 0% no-repeat padding-box;
  
  .swiper-wrapper {
      z-index: 0;
  }

  .ui.card {
    border-radius: 0;
    box-shadow: 0 3px 20px rgba(0,0,0,0.1);

    &>:first-child {
      border-radius: 0 !important;
    }

    a.image {
      display: flex;
      align-items: center;
      min-height: 270px;
      max-height: 270px;
      overflow: hidden;
      padding: 11px 15px;
      background-color: #fff;
    }

    .content {
      border-top: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      p.power {
        color: $green;
        font-size: 0.75em;
        font-weight: 700;
      }

      h3 {
        margin-bottom: 0.8em;
        margin-top: 0;
        font-size: 0.82em;
        font-weight: 400;

        a {
          color: $color-contrast-high;
        }
      }

      small.product-code {
        margin-top: 0.8em;
        font-size: 0.55em;
        color: $color-contrast-low;
      }
      
      .sylius-product-price {
          color: $primary;
          font-size: 0.95em;
          font-weight: 600;
          margin-top: 0em;
      }
      
      .sylius-original-price {
          color: $color-contrast-low;
          font-size: 0.55em;
          font-weight: 400;
          margin-top: 0em;
      }
    }
  }
}

.homepage-latestproducts-container__header {
  padding-top: 5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}
.homepage-latestproducts-container__title {
  font-size: 2.56rem;
  color: $color-contrast-low;
  font-weight: 400;
}
.homepage-latestproducts-container__buttons {
  user-select: none;
}


/* Top solutions baner section **************/
.homepage-primarydark-container {
  background-color: $primary-darker;
  padding-top: 50px;
  margin-top: -100px;

  @media (max-width: $tablet - 1) {
    display: none;
  }
}

.homepage-second-baner-container {
  position: relative;
  bottom: -100px;
  margin-bottom: 100px;

  img {
    min-height: 210px;
    box-shadow: 0px 15px 30px transparentize($primary-darker, 0.6);
    max-width: 100%;
    height: auto !important;
  }

  h2 {
    position: absolute;
    font-size: 2rem;
    font-weight: 400;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    @media (min-width: $tablet) {
      font-size: 2.56rem;
    }
  }
}


/* Blog baner section *************************/
.homepage-blog-container,
.articles-gallery-container,
.articles-gallery-in-content-container
{
  margin-top: 5rem;
  margin-bottom: 2rem;

  .ui.card {
    border-radius: 0;
    box-shadow: 0 3px 20px rgba(0,0,0,0.1);

    &>:first-child {
      border-radius: 0 !important;
    }

    a.image {
      padding: 1em;
      height: 195px;
      overflow: hidden;
    }

    .image {
      background-color: #fff;
    }

    .content {
      border-top: none;

      .meta {
        font-size: 1.25em;
        font-weight: 300;
        color: $primary-darker;
        margin-bottom: 14px;
      }

      h3 {
        font-size: 1em;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;

        a {
          color: $primary-darker;
        }
      }
    }

    .extra.content {
      .ui.basic.label {
        border: none;
        padding-bottom: 15px;
        padding-top: 15px;
      }
    }

    &>.extra {
      border-top: none !important;
    }
  }
}

.homepage-blog-container__header {
  margin-bottom: 2rem !important;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}
.homepage-blog-container__title {
  margin-right: $gap;
  color: $primary-darker;
  font-size: 2.56rem;
  font-weight: 400;
}
.homepage-blog-container__buttons {
  user-select: none;

  .show-all {
    width: 15em;
  }
}


/* Newsletter section **********************/
.homepage-newsletter-container {
  background: $primary-darker url('../../media/newsletterbg.png') no-repeat;
  background-size: cover;
  padding-bottom: 10vw;
  padding-top: 10vw;

  .newsletter {
    color: $color-inverted-contrast-high;
    align-items: baseline;

    input[type="text"] {
      color: #fff;
      background-color: $primary-dark;
      border-radius: 0;
    }
    
    input[type="email"] {
      color: #fff;
      background-color: $primary-dark;
      border-radius: 0;
    }

    .button {
      background-color: $button-bg;
      color: #fff;
      border-radius: 0!important;
    }
    & > .right {
      display: flex !important;
      flex-direction: column;
      justify-content: flex-end;
    }
    
    .newsletter-help {
        font-size: 11px;
        margin-top: 6px;
        
        a {
            color: #fff;
        }
    }
  }
  .newsletter__title {
    color: $color-inverted-contrast-high;
    font-size: 2.38rem;
    font-weight: 400;
  }

  @media (min-width:$desktop) {
    padding-bottom: 8rem;
    padding-top: 8rem;
  }
}


/* what is that? */
.homepage-bottom-container {
  padding-top: 65px;
  padding-bottom: 65px;
  background-color: $primary;

  h5 {
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 1.2px;
  }
}

.swiper-container-horizontal {
    .product-list-item__addtocart-wrapper {
      grid-area: cartadd;
      margin-bottom: 1em !important;

      &> .addtocart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @media (max-width: $tablet) {
            display: grid !important;
            justify-content: stretch !important;
        }

        .cart__variations.hidden {
          display: none;
        }

        .cart__amount {
          display: flex;
          align-items: center;
          margin-right: $gap;
          position: relative;
          
          @media (max-width: $tablet) {
              margin-bottom: 1.2em;
          }

          &>.cart__amount-unit {
              position: absolute;
              left: 0;
              display: block;
              bottom: -15px;
              font-size: 0.6rem;
              width: 100%;
              text-align: center;
              color: $color-contrast-low;

              @media(min-width: $desktop){
                  bottom: -20px;
                  font-size: 0.7rem
              }
          }

          .amount__more,
          .amount__less {
            border: 1px solid $primary;
            border-radius: 50%;
            height: 22px;
            width: 22px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            color: $primary;
            font-weight: 700;
          }

          .amount__count {
            color: $primary-dark;
            font-size: 1rem;
            font-weight: 700;
            margin: 0 8px;
            padding: 0.5em 0;
            width: 4em;
            text-align: center;
            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        .button.ui.primary.cart__add {
          text-transform: uppercase;
          color: #fff;
          /*background-color: $button-add-to-cart;*/
          padding: 14px 45px;
          letter-spacing: 1.2px;
          text-align: center;
          border-radius: 10px;
          font-size: 0.55rem;
          font-weight: 700;
          width: auto;
          padding-bottom: 1.5em;
          padding-top: 1.5em;
          margin-right: 0;

          &:hover {
              /*background-color: $button-add-to-cart-hover;*/
          }
        }
      }

      @media (min-width: $tablet) {
          &> .addtocart {
              justify-content: center;

              .cart__add {
                  width: auto;
              }
          }
      }

      .package-info__quantity {
          position: absolute;
          left: 0;
          bottom: -1em;

          small {
              font-size: 0.7rem;
              color: $color-contrast-medium;

              &>.cart__amount-unit {
                  position: relative;
                  left: 1px;
              }
          }


          @media (min-width: $tablet) {
              bottom: -1.5em;
          }
      }

      .ui.mini.warning.message.max-inventory__warning {
          font-size: 0.75rem;
          margin-top: 2em;
          padding: 0.5em 1em;
          white-space: normal;
          display: none;
      }

      .ui.mini.warning.message.max-unprospective__warning {
          font-size: 0.75rem;
          margin-top: 2em;
          padding: 0.5em 1em;
          white-space: normal;
          display: none;
      }
    }
}