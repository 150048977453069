@mixin box-separator($color, $sides, $gap: 0, $xOffset: 0, $yOffset: 0) {
    $boxShadows: ();

    @for $i from 0 to length($sides) {
        $side: nth($sides, $i + 1);
        @if $side == "right" {
            $boxShadows: append($boxShadows,($xOffset + $gap + 1) $yOffset 0 (-$gap) $color, comma);
        }
        @if $side == "left" {
            $boxShadows: append($boxShadows, (-($gap + 1) + $xOffset) $yOffset 0 (-$gap) $color, comma);
        }
        @if $side == "top" {
            $boxShadows: append($boxShadows, $xOffset (-($gap + 1) + $yOffset) 0 (-$gap) $color, comma);
        }
        @if $side == "bottom" {
            $boxShadows: append($boxShadows, $xOffset ($yOffset + $gap + 1) 0 (-$gap) $color, comma);
        }
    }

    box-shadow: $boxShadows;
}

@mixin expanded-left-column($leftColFr, $rightColFr, $container-width) {
    display: grid;
    grid-template-columns: #{$leftColFr}fr #{$rightColFr}fr;

    @media (min-width: $container-width) {
        grid-template-columns: calc(50% + #{($leftColFr - 0.5) * $container-width}) ($container-width * $rightColFr);
    }
}
