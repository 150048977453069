html, body {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $base-fonts;

  @media (min-width: $wide-desktop) {
    font-size: $base-font-size + 2px;
  }
}
